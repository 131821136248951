<template>
  <div class="content">
    <div class="btnBox">
      <div></div>
      <el-button type="primary" class="mb10" size="mini" @click="tapBtn('添加','')">添加标签</el-button>
    </div>
    <!--列表-->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="list"
          size="small"
          border
          row-key="id"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <el-table-column prop="name" label="标签名称"></el-table-column>
          <el-table-column prop="create_time" label="创建时间"></el-table-column>
          <el-table-column label="操作" width="160">
            <template slot-scope="{ row }">
              <span class="theme" size="mini" @click="tapBtn('编辑',row)">编 辑</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 添加 -->
    <el-dialog :title="`${tit}标签`" :visible.sync="visitClient" :close-on-click-modal="false">
      <el-form
        ref="detail"
        label-position="right"
        label-width="120px"
        :rules="rules"
        :model="detail"
        size="mini"
        class="visit"
      >
        <el-form-item label="标签名称" prop="name">
          <el-input v-model="detail.name" class="w300" placeholder="请输入标签名称" />
        </el-form-item>
        <el-form-item label="是否有上级">
          <el-radio-group v-model="detail.isPid" :disabled="superior">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="detail.isPid == 1" label="所属上级" prop="pid">
          <el-select v-model="detail.pid" class="w300" clearable placeholder="请选择所属上级">
            <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <div class="footerBtn">
          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('detail')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  read_member_label,
  add_member_label,
  save_member_label
} from "@/api/permission";

class Detail {
  name = "";
  isPid = 0;
  pid = "";
}
class Rules {
  name = [{ required: true, message: "请输入标签名称", trigger: "blur" }];
  pid = [{ required: true, message: "请选择所属上级", trigger: "change" }];
}
export default {
  name: "LabelList",
  data() {
    return {
      detail: new Detail(),
      rules: new Rules(),
      list: [],
      visitClient: false,
      tit: "",
      superior: false
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    // 回访列表
    getList() {
      read_member_label().then(res => {
        this.list = res.data.list;
      });
    },
    // 点击添加/编辑
    tapBtn(type, row) {
      this.tit = type;
      this.visitClient = true;
      if (type == "编辑") {
        this.detail.name = row.name;
        this.detail.id = row.id;
        this.detail.pid = row.pid;
        this.detail.isPid = row.pid == 0 ? 0 : 1;
        this.superior = true;
      } else {
        this.detail = new Detail();
        this.superior = false;
      }
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.detail.pid = this.detail.isPid == 0 ? 0 : this.detail.pid;
          this.hint(this.tit == "添加" ? "添加此标签" : "编辑此标签");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提示
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确认提交
    confirm() {
      let resT = {};
      if (this.tit == "添加") {
        add_member_label({ ...this.detail }).then(res => {
          resT = res;
        });
      } else {
        save_member_label({ ...this.detail }).then(res => {
          resT = res;
        });
      }
      this.$message({
        type: "success",
        message: resT.msg
      });
      this.visitClient = false;
      this.detail = new Detail();
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}
.btnBox {
  display: flex;
  justify-content: space-between;
}
.el-form-item {
  margin: 0px 0px 26px 0;
}
.center {
  width: 100% !important;
}
.footerBtn {
  width: 100%;
  text-align: right;
}
</style>
